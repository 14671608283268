<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as JOB_RISK_TYPES } from './store'
import { ROUTES as JOB_RISK_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import JobRiskTable from './JobRiskTable'
import JobRiskCommonFilters from './JobRiskCommonFilters'
import JobRiskEdit from './JobRiskEdit.vue'

export default {
  name: 'JobRiskList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': JobRiskTable,
    'common-filters': JobRiskCommonFilters,
    'component-detail': JobRiskEdit,
    'component-edit': JobRiskEdit
  },
  data () {
    return {
      JOB_RISK_ROUTES,
      title: this.$t('Risk levels'),
      rolePerm: ['miscellaneous_jobrisk_list'],
      actionEnablePermission: ['miscellaneous_jobrisk_enable'],
      actionDisablePermission: ['miscellaneous_jobrisk_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...JOB_RISK_TYPES.GENERIC.miscellaneous.jobrisk.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.JOB_RISK_ROUTES.JOB_RISK_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('miscellaneous_jobrisk_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: JOB_RISK_TYPES.GENERIC.miscellaneous.jobrisk.LIST.MUTATIONS.setSelectedList,
      setSelectAll: JOB_RISK_TYPES.GENERIC.miscellaneous.jobrisk.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...JOB_RISK_TYPES.GENERIC.miscellaneous.jobrisk.LIST.ACTIONS
    })
  }
}
</script>
